:root{
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  background: #004B28; /* WebKit/Blink Browsers */
  color: "#FFFFFF";
}
::-moz-selection {
  background: #004B28; /* Gecko Browsers */
  color: "#FFFFFF";
}


